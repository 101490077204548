import * as React from "react";
import OtherServices from "@components/Services/OtherServices";
import ContactForm from "@components/ContactForm";
import * as styles from "./Websites.module.scss";

import designedDeveloped from "@images/services/websites_page/1_orange.svg";
import javascript from "@images/services/websites_page/2_orange.svg";
import wordpress from "@images/services/websites_page/3_orange.svg";

const WebsitesComponent = ({metadata}) => {
  const IMAGES = {
    "designedDeveloped": designedDeveloped,
    "javascript": javascript,
    "wordpress": wordpress
  }

  console.log({metadata});

  return (
    <div className={`${styles.websites} container`}>
      <section className={styles.header}>
        <h1>{metadata.title}</h1>
      </section>
      {
        metadata.website_feature.map((featureElement, key) => <section key={key} className={styles.websites_feature}>
          <div className={styles.feature_text}>
            <p>{featureElement.text}</p>
          </div>
          <img
            src={IMAGES[featureElement.image]}
            className={`${styles.websites_img} ${styles.img_one}`}
          />
        </section>)
      }
  
      <OtherServices metadata={metadata.other_services} />
  
      <section>
        <ContactForm />
      </section>
    </div>
  );
} 

export default WebsitesComponent;
